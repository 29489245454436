import React from 'react';
import Bridal from '../../Assets/Bridal.png';
import HairStyling from '../../Assets/HairStyling.png';
import NailArt from '../../Assets/NailArt.png';
import PedicureManicure from '../../Assets/PedicureManicure.png';

const OurCategories = () => {
 
    const categories = [
        { title: "Bridal", imgSrc: Bridal, alt: "A bride in traditional attire", animation: "fade-up" },
        { title: "Hair Styling", imgSrc: HairStyling, alt: "A person styling blonde hair", animation: "zoom-in" },
        { title: "Nail Art", imgSrc: NailArt, alt: "A person applying nail polish", animation: "flip-left" },
        { title: "Pedicure & Manicure", imgSrc: PedicureManicure, alt: "A person receiving a pedicure", animation: "slide-right" }
    ];

    return (
        <div className="md:mx-16 mx-6">
            <div className="text-center py-10">
                <h1 className="text-4xl tracking-wide font-bold text-[#FFFFFF]">Our Categories</h1>
            </div>
            <div className="">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                    {categories.map((category, index) => (
                        <div key={index} data-aos={category.animation}> 
                            <img src={category.imgSrc} alt={category.alt} className="object-cover w-full h-full" />
                            <p className="text-center font-medium tracking-wide text-white">{category.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurCategories;
