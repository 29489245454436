import React, { useEffect, useState } from 'react';
import SpaPackages from '../../Assets/SpaPackages.png';
import SpaPackagesicons from '../../Assets/SpaPackagesicons.png';
import NailServices from '../../Assets/NailServices.png';
import NailServicesicons from '../../Assets/NailServicesicons.png';
import SkinCare from '../../Assets/SkinCare.png';
import SkinCareicons from '../../Assets/SkinCareicons.png';
import HealthWellnessIcon from '../../Assets/HealthWellnessIcon.png';
import RelaxationIcon from '../../Assets/RelaxationIcon.png';
import HairStylingIcon from '../../Assets/HairStylingIcon.png';
import img4 from '../../Assets/Service1.jpg'
import img5 from '../../Assets/Service2.jpg'
import img6 from '../../Assets/Service3.jpg'
import axios from 'axios'

const ServiceCard = ({ image, icon, title, description, price, animation }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:scale-105 transition-all duration-300 ease-in-out" data-aos={animation}>
        <div className='relative'>
            <img src={image} alt={title} className="w-full object-cover" />
            <img src={SpaPackagesicons} alt='icons' className='absolute xl:-bottom-10 xl:left-40 lg:-bottom-12 lg:left-24 lg:left-30 md:-bottom-12 md:left-28 -bottom-10 left-24' />
        </div>
        <div className="p-6 mt-7">
            <h2 className="text-xl font-semibold text-center mb-2">{title}</h2>
            <p className="text-gray-600 text-center mb-4">{description}</p>
            <p className="text-center text-black font-semibold mb-4">Starts From: ₹{price}/-</p>
            <div className="text-center">
                <button className="bg-[#000000] text-white  py-3 w-36 rounded">Book Now</button>

            </div>
        </div>
    </div>
);

const OurServices = () => {
    const [services, setServices] = useState([]);
    const getAllServices = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/services//getAllServiceByBranchId/674587629aa6780fb268869e`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                // console.log(response.data);
                setServices(response.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        getAllServices();
    }, [])
    return (
        <div className="md:m-16 m-6 text-center flex flex-col items-center">
            <h1 className="text-4xl font-semibold tracking-wide text-center text-white mb-4">Our Services</h1>
            <p className=" text-[#EEEEEE] w-full lg:w-[60%] capitalize opacity-80 tracking-wide">
                We Seek To Build A Lasting Relationship With You Based On Excellence, Integrity And Forward-Thinking. FG
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-7">
                {services.map((service, index) => (
                    <ServiceCard
                        key={index}
                        image={service.image}
                        icon={SpaPackagesicons}
                        title={service.title}
                        description={service.description}
                        price={service.price}
                        animation={service.animation}
                    />
                ))}
            </div>
        </div>
    );
};
export default OurServices;