import React from 'react'
import image from '../../Assets/footerbg.png'
import { FaFacebookF, FaInstagram, FaPinterestSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Kaira Salon.svg'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { BsEnvelopeFill } from "react-icons/bs";


const Footer = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <footer className="footer-bg bg-cover bg-center" style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
                <div className="px-4 py-10">
                    <div className="grid md:grid-cols-[1.5fr_2fr_1fr] lg:grid-cols-[1fr_2fr_1fr] grid-cols-1 gap-10 w-[90%] mx-auto">
                        <div className="md:ml-11">
                            <h2 className="text-lg font-bold mb-4 text-white">QUICK LINKS</h2>
                            <ul className='text-white'>
                                <li onClick={() => { navigate('/'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Home</li>
                                <li onClick={() => { navigate('/about'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">About Us</li>
                                <li onClick={() => { navigate('/services'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Services</li>
                                <li onClick={() => { navigate('/offers'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Offers</li>
                                <li onClick={() => { navigate('/contact'); window.scrollTo(600, 0); }} className="mb-2 flex items-center cursor-pointer">Contact</li>
                            </ul>
                        </div>
                        <div className="text-center md:mr-16 text-white flex flex-col items-start md:items-center">
                            <img src={logo} alt="logo" className='w-[8rem] md:w-[10rem]'/>
                            <p className="mb-4 text-start md:text-center mt-4">Passionate Experts Ready to Serve You: Uniting Skill, Dedication, and Innovation for Your Ultimate Satisfaction.</p>
                            <div className="flex justify-center space-x-4 text-white">
                                <div>
                                    <a href='https://www.facebook.com/p/Kaira-Salon-61555288714974/' target="_blank" rel="noopener noreferrer" >
                                        <FaFacebookF/></a>

                                </div>
                                <div>
                                    <a href="https://www.instagram.com/kaira.salon/" target="_blank" rel="noopener noreferrer">
                                        <FaInstagram/>
                                    </a>
                                </div>
                                <FaTwitter />
                                
                                <FaYoutubeSquare />
                                <FaPinterestSquare />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold mb-4 text-white">CONTACT US</h2>
                            <div className='flex items-center gap-4 mb-4 text-white'>
                                <FaMapMarkerAlt className='text-xl'/>
                                <p className="mb-2 white">Fast floor, Plot no - 2726/2727, Beheramal, near Domino’s pizza, Jharsuguda, Odisha</p>
                            </div>
                            <div className='flex items-center gap-4 mb-4 text-white'>
                                <BsEnvelopeFill className='text-xl'/>
                                <p className="mb-2">kairasaloon@gmail.com</p>
                            </div><div className='flex items-center gap-4 mb-4 text-white'>
                                <FaPhoneAlt className='text-xl'/>
                                <p className="mb-2">+91-9777172793</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="py-4 bg-[#191919] text-white">
                <div className="md:mx-16 px-4 flex flex-wrap justify-between items-center">
                    <p className="w-full sm:w-auto text-center sm:text-left">© {currentYear} KAIRA SALON  All Rights Reserved.</p>
                    <p className="w-full sm:w-auto text-center sm:text-right">
                        Design & Developed by{' '}
                        <a
                            href="https://www.webbocket.com/"
                            target="_blank"
                            rel="noopener noreferrer"                        >
                            WEB_BOCKET
                        </a>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer;