import React from 'react'
import OurCategories from '../../Components/Home/OurCategories'
import OurServices from '../../Components/Home/OurServices'
import OurPricing from '../../Components/Home/OurPricing'
import ClientsSay from '../../Components/Home/ClientsSay'
import OurTeam from '../../Components/Home/OurTeam'
import SubscribeNewsletter from '../../Components/Home/SubscribeNewsletter'
import BookAppointment from '../../Components/Home/BookAppointment'
import HeroSection from '../../Components/Home/HeroSection'
import OurGallery from '../../Components/Home/OurGallery'
import WhyOurClients from '../../Components/Home/WhyOurClients'
import OffersZone from '../../Components/Home/OffersZone'
const Home = () => {
  return (
    <div className='overflow-x-hidden'>
      <HeroSection />
      <OffersZone />
      <OurCategories />
      <WhyOurClients />
      <OurServices />
      <OurPricing />
      <OurGallery />
      <ClientsSay />
      <OurTeam />
      <BookAppointment />
      <SubscribeNewsletter />
    </div>
  )
}
export default Home 