import React from "react";

const Map = () => {
  return (
    <React.Fragment>
      <div>
        <div className="w-full h-80 lg:h-[500px] mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3702.655836451394!2d84.02177477505633!3d21.87080707999769!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a20e59751cc8ac1%3A0xc748db51dae6658b!2sKaira%20salon!5e0!3m2!1sen!2sin!4v1734335818894!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Map;
