import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/abouthomebg.png'
import OurOffers from '../../Components/Offers/OurOffers'
import BookAppointment from '../../Components/Home/BookAppointment'
import SubscribeNewsletter from '../../Components/Home/SubscribeNewsletter'
const Offers = () => {
  return (
    <div className='overflow-x-hidden'>
        <SimpleBanner
          image={AboutUs_banner}
          name="Our Offers"
          path="Home / Our Offers"
        />
        
        <OurOffers/>
        <BookAppointment/>
        <SubscribeNewsletter/>
    </div>
  )
}

export default Offers
