import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/abouthomebg.png'
import OurServices from '../../Components/Home/OurServices'
import SubscribeNewsletter from '../../Components/Home/SubscribeNewsletter'
import OurTeam from '../../Components/Home/OurTeam'
import BookAppointment from '../../Components/Home/BookAppointment'

const Services = () => {
  return (
    <div className='overflow-x-hidden'>
        <SimpleBanner
        image={AboutUs_banner}
        name="Services"
        path="Home / Services"
      />
   
    <OurServices/>
    <OurTeam/>
    <BookAppointment/>
    <SubscribeNewsletter/>
    </div>
  )
}

export default Services
//create a designe add only add palece for only imge create the designe using react js and tailwind css
