import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Team1 from "../../Assets/Team1.png";
import Team2 from "../../Assets/Team2.png";
import Team3 from "../../Assets/Team3.png";
import Team4 from "../../Assets/Team4.png";
import { useState } from "react";
import axios from "axios";

const OurTeam = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [team, setTeam] = useState([]);
  const showTeam = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        setTeam(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showTeam();
  }, []);

  console.log("team : ", team);

  return (
    <div className="py-10">
      <div className="text-center py-12 flex flex-col items-center">
        <h1 className="text-4xl font-bold text-white tracking-wide">
          Meet Our Team
        </h1>
        <p className="mt-4 text-white md:w-[70%] tracking-wide leading-relaxed">
          Passionate Experts Ready To Serve You: Uniting Skill, Dedication, And
          Innovation For Your Ultimate Satisfaction.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-6 md:mx-16">
        {team.map((member, index) => (
          <div
            key={index}
            className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden"
            data-aos="fade-up"
          >
            <img
              alt={`Portrait of ${member.name}`}
              className="w-full object-cover"
              src={member?.profilePic}
            />
            <div className="p-4 text-center">
              <h2 className="text-xl font-bold mb-4 tracking-wide">
                {member?.name}
              </h2>
              <p className="text-gray-600 tracking-wide">{member?.role}</p>
            </div>
          </div>
        ))}
        {/* <div
          className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden"
          data-aos="fade-up"
        >
          <img
            alt="Portrait of Akshay Jeet"
            className="w-full object-cover"
            src={Team1}
          />
          <div className="p-4 text-center">
            <h2 className="text-xl font-bold mb-4 tracking-wide">
              Akshay Jeet
            </h2>
            <p className="text-gray-600 tracking-wide">Hairdresser</p>
          </div>
        </div>

        <div
          className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden"
          data-aos="zoom-in"
        >
          <img
            alt="Portrait of Tanu Jain"
            className="w-full object-cover"
            src={Team2}
          />
          <div className="p-4 text-center">
            <h2 className="text-xl font-bold mb-4 tracking-wide">Tanu Jain</h2>
            <p className="text-gray-600 tracking-wide">Beauty Therapist</p>
          </div>
        </div>

        <div
          className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden"
          data-aos="slide-left"
        >
          <img
            alt="Portrait of Tanu Jain"
            className="w-full object-cover"
            src={Team3}
          />
          <div className="p-4 text-center">
            <h2 className="text-xl font-bold mb-4 tracking-wide">Tanu Jain</h2>
            <p className="text-gray-600 tracking-wide">Beauty Therapist</p>
          </div>
        </div>

        <div
          className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden"
          data-aos="flip-left"
        >
          <img
            alt="Portrait of Tanu Jain"
            className="w-full object-cover"
            src={Team4}
          />
          <div className="p-4 text-center">
            <h2 className="text-xl font-bold mb-4 tracking-wide">Tanu Jain</h2>
            <p className="text-gray-600 tracking-wide">Beauty Therapist</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurTeam;
