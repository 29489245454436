import React, { useEffect, useState } from "react";
import star from "../../Assets/star.png";
import axios from "axios";

const PricingCard = ({ plan, animation }) => (
  <div
    className="bg-white shadow-lg rounded-lg overflow-hidden mb-6 mx-2"
    data-aos={animation}
  >
    <div className="relative">
      <img src={plan.image} alt={plan.alt} className="object-cover w-full" />
      <div className="absolute top-0 left-0 bg-gray-800 text-white text-xs font-semibold px-2 py-1 rounded-br-lg">
        {plan.type}
      </div>
      <div className="absolute bottom-0 left-0 bg-gray-800 text-white text-2xl px-4 py-2">
        ₹{plan.price}
        <span className="text-sm"> /per day</span>
      </div>
    </div>
    <div className="p-6">
      <ul className="space-y-2 text-black">
        {plan.keyPoints.map((feature, index) => (
          <li key={index} className="flex items-center gap-4">
            <img src={star} alt="" />
            {feature}
          </li>
        ))}
      </ul>
      <button className="mt-6 w-full bg-black text-white py-2 rounded-lg font-semibold hover:bg-[#5d5d5d]">
        Choose Plan
      </button>
    </div>
  </div>
);

const OurPricing = () => {
  const animations = ["fade-up", "fade-right", "fade-left"];
  const [plans, setPlans] = useState([]);
  const getALlPlans = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/plans/getAllPlansByBranchId/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);

        setPlans(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getALlPlans();
  }, []);
  return (
    <div className=" text-gray-800">
      <div className="text-center py-12">
        <h1 className="text-4xl font-semibold text-white tracking-wide">
          Our Pricing
        </h1>
        <p className="mt-4 text-lg text-white tracking-wide leading-loose">
          We Seek To Build A Lasting Relationship With You Based On Excellence,
          Integrity And Forward-Thinking.
        </p>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 mx-6 md:mx-16">
        {plans.map((plan, index) => (
          <PricingCard
            key={index}
            plan={plan}
            animation={animations[index % animations.length]}
          />
        ))}
      </div>
    </div>
  );
};

export default OurPricing;
