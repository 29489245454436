import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/abouthomebg.png'
import OurServices from '../../Components/Home/OurServices'
import SubscribeNewsletter from '../../Components/Home/SubscribeNewsletter'
import OurTeam from '../../Components/Home/OurTeam'
import ClientsSay from '../../Components/Home/ClientsSay'
import AboutBeing from '../../Components/About/AboutBeing'
const About = () => {
  return (
    <div className='overflow-x-hidden'>
        <SimpleBanner
          image={AboutUs_banner}
          name="About Us"
          path="Home / About us"
        />
      <AboutBeing/>
      <OurServices />
      <OurTeam />
      <ClientsSay />
      <SubscribeNewsletter />
    </div>
  )
}

export default About