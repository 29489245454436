import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import emailjs from "emailjs-com";
import logo from "../../Assets/Kaira Salon.svg";

const AppointmentModal = ({ onClose }) => {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_xyvcc1e",
        "template_voinpgp",
        formData,
        "z7shN8aJoJopttBxt"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          onClose();
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <div
      ref={modalRef}
      className="fixed inset-0 z-50 backdrop-blur-sm flex items-center justify-center p-4 sm:p-6"
      onClick={closeModal}
    >
      <form
        className="w-full sm:max-w-md md:max-w-lg lg:max-w-xl bg-[#FFFFFF] px-6 sm:px-8 md:px-16 py-8 sm:py-10 md:py-12 rounded-md border shadow-lg"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-black tracking-wide text-xl sm:text-2xl md:text-3xl font-medium">
            Book Appointment 
          </h1>
          <IoClose
            size={25}
            className="cursor-pointer text-black"
            onClick={onClose}
          />
        </div>
        <div className="space-y-4 sm:space-y-6 mt-6 sm:mt-8">
          <input
            type="text"
            className="w-full p-3 rounded-md text-black border-2 focus:outline-gray-500"
            placeholder="First Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />

          <input
            type="email"
            className="w-full p-3 rounded-md text-black border-2 focus:outline-gray-500"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />

          <input
            type="tel"
            className="w-full p-3 rounded-md text-black border-2 focus:outline-gray-500"
            placeholder="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />

          <input
            type="text"
            className="w-full p-3 rounded-md text-black border-2 focus:outline-gray-500"
            placeholder="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />

          <div className="flex items-center justify-between">
            <img src={logo} alt="logo" className="w-24 sm:w-28 md:w-32 mt-8" />
            <div className="text-center mt-8">
              <button className="bg-[#000000] text-white py-2 w-36 rounded hover:bg-[#5d5d5d] ">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AppointmentModal;
