import React from "react";
import Gallery1 from "../../Assets/Gallery1.png";
import Gallery2 from "../../Assets/Gallery2.png";
import Gallery3 from "../../Assets/Gallery3.png";
import Gallery4 from "../../Assets/Gallery4.png";
import Gallery5 from "../../Assets/Gallery5.png";
import Gallery6 from "../../Assets/Gallery6.png";
import Gallery7 from "../../Assets/Gallery7.png";
import Gallery8 from "../../Assets/Gallery8.png";
import Gallery9 from "../../Assets/Gallery9.png";
import Gallery10 from "../../Assets/smallglry.png";
import { useState, useEffect } from "react";
import axios from "axios";

const OurGallery = () => {
  const [allGallery, setAllGallery] = useState([]);

  const showAllgalleryData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/getGallery/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setAllGallery(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("Gallery----->", allGallery);

  useEffect(() => {
    showAllgalleryData();
  }, []);

  return (
    <div className="m-6">
      <div className="text-center pb-6">
        <h1 className="text-4xl font-semibold text-white tracking-wide">
          Our Gallery
        </h1>
      </div>
      <div className="md:hidden flex justify-center items-center ">
        <img
          src={allGallery[0]?.gallery}
          alt="img"
          className="h-auto w-full object-contain"
          data-aos="fade-up"
        />
      </div>
      <div className="hidden md:grid grid-cols-2 md:w-[30%] mx-auto md:pb-8 pb-4 gap-4 md:gap-8">
        <img
          src={allGallery[0]?.gallery}
          alt="img"
          className="mt-auto"
          data-aos="fade-right"
        />
        <img
          src={allGallery[1]?.gallery}
          alt="img"
          className="mt-auto"
          data-aos="fade-left"
        />
      </div>
      <div className="hidden md:grid grid-cols-3 md:w-[100%] mx-auto md:gap-8 gap-4">
        <div className="md:space-y-8">
          <img
            src={allGallery[2]?.gallery}
            alt="img"
            className="float-right"
            data-aos="zoom-in"
          />
          <img
            src={allGallery[3]?.gallery}
            alt="img"
            className="float-right"
            data-aos="zoom-in"
          />
        </div>
        <div>
          <img
            src={allGallery[4]?.gallery}
            alt="img"
            className="md:h-full md:w-full object-cover"
            data-aos="flip-left"
          />
        </div>
        <div className="space-y-8">
          <img src={allGallery[5]?.gallery} alt="img" data-aos="fade-up" />
          <img src={allGallery[6]?.gallery} alt="img" data-aos="fade-up" />
        </div>
      </div>
      <div className="hidden md:grid grid-cols-2 md:w-[30%] mx-auto pt-8 gap-8">
        <img src={allGallery[7]?.gallery} alt="img" data-aos="fade-right" />
        <img src={allGallery[8]?.gallery} alt="img" data-aos="fade-left" />
      </div>
    </div>
  );
};
export default OurGallery;
