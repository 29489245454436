import React from 'react'
import image from '../../Assets/BookAppointmentbg.png'
const BookAppointment = () => {
  return (
    <div
      className='flex items-center justify-center mt-7'
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className=" p-6 md:p-24 rounded-lg z-10 md:w-[70%] ">
        <h1 className="text-4xl font-bold text-black mb-4 md:mb-8 text-center tracking-wide">Book An Appointment!</h1>
        <form className="space-y-4">
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            <input type="text" placeholder="Name" className="w-full md:w-1/2 p-3 border border-gray-400 rounded-md focus:outline-none" />
            <input type="text" placeholder="Number" className="w-full md:w-1/2 p-3 border border-gray-400  rounded-md focus:outline-none" />
          </div>
          <input type="text" placeholder="Subject" className="w-full p-3 border border-gray-400  rounded-md focus:outline-none" />
          <textarea placeholder="Tell Us More" className="w-full p-3 border border-gray-400 rounded-md focus:outline-none  h-32"></textarea>
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 justify-center">
          <div className="text-center flex flex-col md:flex-row items-center gap-4">
               <button className="bg-[#000000] text-white  py-3 w-36 rounded hover:bg-[#5d5d5d]">Book Now</button>
               <button type="button" className="border border-gray-400 py-3 w-24 rounded-md hover:bg-gray-400">Skip</button>

           </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default BookAppointment