import React from "react";
import img from "../../Assets/about.png";
const AboutBeing = () => {
  return (
    <div className="mx-auto p-6 flex flex-col lg:flex-row items-center gap-x-10 space-y-6">
      <div className="lg:w-1/2 relative">
        <div className="w-full">
          <img
            src={img}
            alt="A person with styled hair being worked on by a stylist"
            className="rounded-lg object-cover shadow-lg md:h-[35rem] mx-auto"
          />
        </div>
        <div className="bg-gray-500 text-white p-4 md:w-[20rem] rounded-lg shadow-lg absolute right-0 bottom-3 md:bottom-16">
          <h2 className="text-lg font-bold">Opening Time</h2>
          <p>Mon-Wed : 9 AM To 8 PM</p>
          <p>Thu-Sat : 10 AM To 9 PM</p>
          <p>Sunday : Closed</p>
        </div>
      </div>
      <div className="lg:w-1/2">
        <div className="w-[90%] mx-auto">
          <h3 className="text-white text-sm font-bold">ABOUT US</h3>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mt-2 text-white tracking-wider  md:leading-loose">
            Beauty Is About Being Comfortable In Your Own Skin.
          </h1>
          <p className="mt-4 text text-[#EEEEEE] tracking-wide leading-loose  ">
            Welcome To Kaira Salon, Where Beauty Meets Innovation. Our Team Of
            Skilled And Certified Professionals Is At The Heart Of Everything We
            Do. With Extensive Training, A Passion For Their Craft, And A
            Commitment To Excellence, Our Stylists, Beauticians, And Therapists
            Ensure That Every Visit Leaves You Feeling Confident And Refreshed.
          </p>
          <div className="mt-4">
            <button className="bg-[#000000] text-white  py-3 w-36 rounded hover:bg-[#5d5d5d]">Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBeing;
