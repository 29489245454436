import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../Assets/Kaira Salon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import { useEffect } from "react";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { TbMail } from "react-icons/tb";
import { MdOutlineLocationOn } from "react-icons/md";
import AppointmentModal from "./AppointmentModal";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState([]);

  const fetchMenuData = async () => {
    // setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/menu/getMenu/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response of Fetching menu: ", response.data);
        setMenu(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "text-base font-semibold text-[#000000] cursor-pointer tracking-wide border-b-2 border-b-black"
      : "text-base font-semibold cursor-pointer tracking-wide";
  };
  return (
    <>
      <nav className="hidden md:block bg-[#252526] text-white px-6 md:px-16 py-2 underline-">
        <div className="container mx-auto flex items-center justify-between relative">
          {/* 1st Section - Phone and Email */}
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-1">
              <MdOutlineLocationOn size={22} />
              <span className="text-sm tracking-wide">Bhubaneswar, Odisha</span>
            </div>
            <div className="flex items-center space-x-2">
              <TbMail size={23} />
              <span className="text-sm tracking-wide">
                kairasaloon@gmail.com
              </span>
            </div>
          </div>
          {/* 3rd Section - Social Media Icons */}
          <div className="flex space-x-4">
            <div>
              <a
                href="https://www.facebook.com/p/Kaira-Salon-61555288714974/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/kaira.salon/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
            <div>
              <a
                href="https://wa.me/9777172793?text=Welcome to Kaira Salon. How may we assist you?%20there%20I%20need%20help!"
                target="_blank"
              >
                <FaWhatsapp />
              </a>
            </div>
            {/* <div className="text-white]">
              <FaFacebookF />
            </div>
            <div className="text-white]">
              <FaTwitter />
            </div>
            <div className="text-white]">
              <FaInstagram />
            </div>
            <div className="text-white]">
              <FaLinkedinIn />
            </div> */}
          </div>
        </div>
      </nav>
      {/* Navbar */}
      <nav className="bg-[#FFFFFF] text-black px-6 md:px-16 py-2 shadow-2xl">
        <div className="container mx-auto flex items-center justify-between">
          {/* 1st Section - Logo */}
          <div>
            <img src={logo} alt="baisaly-logo" className="w-auto h-14" />
          </div>

          {/* Middle Section - Page Links (hidden on small screens) */}
          <div className="hidden lg:flex space-x-8">
            <div onClick={() => navigate("/")} className={getLinkClass("/")}>
              Home
            </div>
            <div
              onClick={() => navigate("/about")}
              className={getLinkClass("/about")}
            >
              About
            </div>
            <div
              onClick={() => navigate("/services")}
              className={getLinkClass("/services")}
            >
              Services
            </div>
            <div
              onClick={() => navigate("/offers")}
              className={getLinkClass("/offers")}
            >
              Offers
            </div>
            <div
              onClick={() => navigate("/contact")}
              className={getLinkClass("/contact")}
            >
              Contact
            </div>
            <div className="text-base font-semibold hover:text-[#000000] cursor-pointer tracking-wide ">
              <a href={menu?.data?.[0]?.menuURL}>Menu</a>
            </div>
          </div>

          {/* 3rd Section - Appointment Button */}
          <div className="hidden lg:block" onClick={handleAddOpen}>
            <button className="tracking-wide bg-black text-white font-bold py-2 px-4 rounded hover:bg-[#252526]">
              Appointment
            </button>
          </div>
          {open && <AppointmentModal onClose={handleModalClose} />}
          {/* Hamburger Menu for Small Screens */}
          <div className="lg:hidden">
            <FaBars
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer"
            />
          </div>
        </div>
      </nav>

      {/* Sidebar for Small Screens */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
          <div className="fixed top-0 left-0 w-64 h-full bg-white shadow-md">
            <div className="p-4">
              <button
                className="text-black text-lg font-bold mb-8 float-right"
                onClick={toggleSidebar}
              >
                <IoClose size={25} className="text-black" />
              </button>

              <ul className="space-y-4 text-lg">
                <li
                  onClick={() => {
                    navigate("/");
                    toggleSidebar();
                  }}
                >
                  <span className={getLinkClass("/")}>Home</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/about");
                    toggleSidebar();
                  }}
                >
                  <span className={getLinkClass("/about")}>About</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/services");
                    toggleSidebar();
                  }}
                >
                  <span className={getLinkClass("/services")}>Services</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/offers");
                    toggleSidebar();
                  }}
                >
                  <span className={getLinkClass("/offers")}>Offers</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/contact");
                    toggleSidebar();
                  }}
                >
                  <span className={getLinkClass("/contact")}>Contact</span>
                </li>

                <li>
                  <div className="text-base font-semibold hover:text-[#000000] cursor-pointer tracking-wide ">
                    <a href={menu?.data?.[0]?.menuURL}>Menu</a>
                  </div>
                </li>
              </ul>
              <button
                className="tracking-wide mt-8 bg-black hover:bg-[#252526] text-white font-bold py-2 px-4 rounded "
                onClick={() => {
                  handleAddOpen();
                  toggleSidebar();
                }}
              >
                Appointment
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
