import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/abouthomebg.png'
import BookAppointment from '../../Components/Home/BookAppointment'
import SubscribeNewsletter from '../../Components/Home/SubscribeNewsletter'
import Map from '../../Components/Contact/Map'
const Contact = () => {
  return (
    <div className='overflow-x-hidden'> 
      <div>
        <SimpleBanner
          image={AboutUs_banner}
          name="Contact"
          path="Home / Contact"
        />
      </div>
      <BookAppointment />
      <Map />
      <SubscribeNewsletter />
    </div>
  )
}

export default Contact
