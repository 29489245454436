import React from 'react'
import img from '../../Assets//imgsection.png'
import img1 from '../../Assets/Vector.svg'
import bgImg from '../../Assets/WhyOurClientBg.png';

const WhyOurClients = () => {
    return (
            <div className="flex flex-col justify-around lg:flex-row mt-10"
            style={{
                backgroundImage: `url(${bgImg})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className=' mx-auto text-white m-6 md:m-16' data-aos="fade-down-right">
                    <h2 className="text-5xl tracking-wide font-bold mb-6 mt-8 leading-relaxed w-full lg:w-[70%]">Why Our Clients Choose Us</h2>
                    <ul className='space-y-6 mt-14'>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Expert Team Of Professionals</span>
                        </li>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Premium Quality Products</span>
                        </li>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Comprehensive Range Of Services</span>
                        </li>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Exceptional Customer Service</span>
                        </li>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Relaxing Spa Services</span>
                        </li>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Custom Beauty Packages</span>
                        </li>
                        <li className="flex items-center">
                            <img src={img1} className='mr-3 w-5' alt='img' />
                            <span className="text-lg">Commitment To Hygiene And Safety</span>
                        </li>
                    </ul>
                </div>
                <div className='py-10 mx-auto' data-aos="fade-down-left">
                    <img src={img} alt='img' />
                </div>
            </div>      
    )
}

export default WhyOurClients