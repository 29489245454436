import React from "react";
import image from "../../Assets/HeroBgImg.png";
import smallImg from "../../Assets/SmallHeroBgImg.png";
import "./HeroSection.css";
import { useState, useEffect } from "react";
import axios from "axios";

const HeroSection = () => {
  const [heroSectionData, setHeroSectionData] = useState();

  const showHeroSection = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response : ", response.data);
        setHeroSectionData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showHeroSection();
  }, []);
  return (
    <React.Fragment>
      <div
        className="hidden md:block md:py-20 h-auto text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="text-start text-[#F1F0F0] p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
          <h1 className="text-3xl md:text-6xl font-medium mb-4 tracking-widest font-serif">
            {/* Where Beauty and Elegance Converg */}
            {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-base md:text-lg mb-6 tracking-wide leading-relaxed">
            {/* Discover Your Signature Style With Our Expert Team: Personalized
            Beauty Journeys In A Luxurious Haven - Where Innovation Meets
            Tradition In The Art Of Beauty. */}
            {heroSectionData?.[0]?.subheading}
          </p>

          <button className="bg-[#F0F0F0] text-black py-3 w-36 rounded hover:bg-[#656565]">
            Book Now
          </button>
        </div>
      </div>

      {/* For Mobile Screen */}
      <div
        className="md:hidden block py-10 h-auto text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
        }}
      >
        <div className="text-start text-white p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
          <h1 className="text-3xl md:text-6xl font-medium mb-4 tracking-wider">
            {/* Where Beauty and Elegance Converg */}
            {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-base md:text-lg mb-6 tracking-wide mt-6">
            {/* Discover Your Signature Style With Our Expert Team: Personalized
            Beauty Journeys In A Luxurious Haven - Where Innovation Meets
            Tradition In The Art Of Beauty. */}
            {heroSectionData?.[0]?.subheading}
          </p>

          <button className="bg-[#F0F0F0] text-black py-3 w-36 rounded  hover:bg-[#656565]">
            Book Now
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
