import React from 'react'
import image from '../../Assets/SubscribeNewsletterbg.png'
const SubscribeNewsletter = () => {
    return (
        <div
            className='flex items-center justify-center py-16'
            style={{
                backgroundImage:`url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <div className="text-center p-8 max-w-4xl mx-auto">
                <h2 className="text-[#1a1a1a] font-bold text-sm uppercase mb-2">JOIN OUR COMMUNITY</h2>
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4 tracking-wide">Subscribe To Our Newsletter</h1>
                <p className="text-black mb-6 tracking-wide leading-relaxed">Passionate Experts Ready To Serve You: Uniting Skill, Dedication, And Innovation For Your Ultimate Satisfaction.</p>
                <div className="flex flex-col items-center md:grid md:grid-cols-3 gap-2 md:gap-0 justify-center">
                    <input type="email" placeholder="Enter your email" className="p-4 rounded-l-xl   w-full md:col-span-2 focus:outline-none bg-gray-200" />
                    <button className="bg-black my-4 hover:bg-[#5d5d5d] text-white p-4 w-[60%] rounded-lg text-center md:rounded-r-xl shadow-md md:w-full">Subscribe Now</button>
                </div>
            </div>
        </div>
    )
}

export default SubscribeNewsletter